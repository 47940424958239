html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: var(--font-nanum);
}

body {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --font-cinzel: "Cinzel Decorative", serif;
  --font-nanum: "Nanum Myeongjo", serif;
}
